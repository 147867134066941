<template>
  <div>
    <va-notification
      v-model="sent"
      color="info"
      closeable
    >
      <va-badge color="success"> Sent </va-badge>
      Please check your email for your password reset instructions.
    </va-notification>

    <form @submit.prevent="resetPassword" class="login">
      <div class="row mb-2">
        <va-input
          v-model="email"
          type="email"
          :label="$t('auth.email')"
          :error="!!emailErrors.length"
          :error-messages="emailErrors"
        />
      </div>

      <div class="row mb-2 text-center">
        <p>Please enter the email associated with your account to recieve instructions to reset your password via email.</p>
      </div>

      <div class="row justify--center">
        <va-button type="submit" class="my-0">
          <p v-if="loading === false"> {{ $t('auth.reset_password') }} </p>
          <p v-else> Loading... </p>
        </va-button>
      </div>
    </form>
  </div>
</template>

<script>
import { auth } from '@/firebaseConfig'

export default {
  name: 'recover-password',
  data () {
    return {
      email: '',
      sent: false,
      loading: false,
      emailErrors: [],
    }
  },
  methods: {
    resetPassword () {
      this.emailErrors = []
      var emailRE = /\w{2,}@\w{2,}\.\w{2,}/

      if (emailRE.test(this.email)) {
        this.sendResetPasswordRequest()
      } else {
        this.emailErrors = ['Email is required']
      }
    },

    sendResetPasswordRequest: function () {
      this.loading = true
      auth
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.sent = true
          this.loading = false
        })
        .catch(() => {
          this.sent = false
          this.loading = false
          this.emailErrors = [
            'There has been an error finding the account ' +
                this.email +
                '. Are you sure your email address is correct?',
          ]
          console.log(this.emailErrors)
        })
    },
  },
}
</script>

<style lang="scss">
</style>
